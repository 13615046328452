<template>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 xl:grid-cols-2">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-2">
      <BalLoadingBlock v-for="n in 4" :key="n" class="h-24" />
    </div>
    <div class="pl-4 pr-8">
      <BalLoadingBlock class="h-48" />
    </div>
  </div>
</template>
