<template>
  <div>
    <div
      class="overflow-hidden leading-none rounded-full"
      :style="{
        width: `${parseInt(size) || 22}px`,
        height: `${parseInt(size) || 22}px`
      }"
    >
      <img :src="AvatarUrl" />
      <!-- <jazzicon :address="address" :diameter="parseInt(size) || 22" /> -->
    </div>

    <!-- <img
      v-if="iconSRC && !error"
      :src="iconSRC"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
        background: 'white'
      }"
      @error="error = true"
      class="inline-block leading-none rounded-full shadow-sm"
    />

    <div
      v-else
      class="overflow-hidden leading-none rounded-full"
      :style="{
        width: `${size}px`,
        height: `${size}px`
      }"
    > -->
      <!-- <jazzicon :address="address" :diameter="size" /> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue';
// import Jazzicon from 'vue3-jazzicon/src/components';
// import useUrls from '@/composables/useUrls';

export default defineComponent({
  name: 'Avatar',

  components: {
    // Jazzicon
  },
  props: ['address', 'size', 'profile'],

  // props: {
  //   address: {
  //     type: String,
  //     required: true
  //   },
  //   iconURI: { type: String },
  //   size: {
  //     type: Number,
  //     default: 24
  //   }
  // },

  setup(props) {
    const sizeInt = parseInt(props.size) || 22;
    const AvatarUrl =
      'https://avatar.tobi.sh/' + props.address + '?size=' + sizeInt.toString();
    //   /**
    //    * COMPOSABLES
    //    */
    //   const { resolve } = useUrls();

    //   /**
    //    * STATE
    //    */
    //   const error = ref(false);

    //   /**
    //    * COMPUTED
    //    */
    //   const iconSRC = computed(() => resolve(props.iconURI));

    //   /**
    //    * WATCHERS
    //    */
    //   watch(iconSRC, newURL => {
    //     if (newURL !== '') error.value = false;
    //   });

    return {
      sizeInt,
      AvatarUrl,
      // jazzicon
      //     iconSRC,
      //     error
    };
  }
});
</script>
