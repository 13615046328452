<template>
  <span>
    <span class="text-center" v-if="currentTime">
      <span v-if="days"> {{ days }} Days </span>

      <span v-if="hours"> {{ hours }}: </span>
      <span v-if="minutes"> {{ minutes }}:</span>

      {{ seconds }}
    </span>
    <!-- <div class="text-center" v-if="!currentTime">
      Time's Up!
    </div> -->
  </span>
</template>

<script>
export default {
  props: {
    deadline: {
      // type: String,
      type: String,
      // type: Number,
      required: true
    },
    speed: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      currentTime: Date.parse(this.deadline) - Date.parse(new Date())
      // currentTime:
      //   parseInt(this.deadline) - Math.floor(Date.parse(new Date()) / 1000)
    };
  },
  mounted() {
    setTimeout(this.countdown, 1000);
  },
  computed: {
    seconds() {
      return Math.floor((this.currentTime / 1000) % 60);
    },
    minutes() {
      return Math.floor((this.currentTime / 1000 / 60) % 60);
    },
    hours() {
      return Math.floor((this.currentTime / (1000 * 60 * 60)) % 24);
    },
    days() {
      return Math.floor(this.currentTime / (1000 * 60 * 60 * 24));
    }
  },
  //   filters: {
  //     formatTime(value) {
  //       if (value < 10) {
  //         return '0' + value;
  //       }
  //       return value;
  //     }
  //   },
  methods: {
    countdown() {
      console.log('this.deadline: ' + this.deadline);
      this.currentTime = Date.parse(this.deadline) - Date.parse(new Date());
      // this.currentTime =
      // parseInt(this.deadline) - Math.floor(Date.parse(new Date()) / 1000);

      if (this.currentTime > 0) {
        setTimeout(this.countdown, this.speed);
      } else {
        this.currentTime = null;
      }
    }
  }
};
</script>
