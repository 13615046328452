<template>
  <div>
    <AppNavDummyAlert />
    <AppNavAlert v-if="currentAlert" :alert="currentAlert" />
    <!-- <nav id="app-nav" ref="appNav" class="sticky top-0 h-20 px-4 lg:px-6"> -->
    <nav id="app-nav" ref="appNav" class="sticky top-0 px-2 rounded h-14">
      <!-- <div class="flex items-center justify-between h-full"> -->
      <div class="flex items-center h-full">
        <!-- <div class="flex items-center w-2/3 lg:w-1/3"> -->
        <div class="flex items-center">
          <!-- <router-link
          :to="{ name: 'home' }"

          @click="trackGoal(Goals.ClickNavLogo)"
        > -->
          <router-link :to="{ name: 'home' }" class="mr-16">
            <AppLogo />
            <!-- <AppIcon v-if="['xs', 'sm', 'md'].includes(bp)" />
            <AppLogo v-else /> -->
          </router-link>
          <!-- <AppNavNetworkSelect v-if="!hideNetworkSelect" /> -->
        </div>

        <!-- <div
          v-if="!upToLargeBreakpoint"
          class="flex justify-center flex-1 md:w-1/3"
        >
          <AppNavToggle />
          <AppNavOtherItems v-if="!upToXLargeBreakpoint" />
        </div> -->

        <!-- <div class="flex justify-end w-1/3">
          <AppNavActions />
        </div> -->

        <div class="flex justify-end flex-1">
          <AppNavToggle v-if="!upToLargeBreakpoint" />
          <AppNavOtherItems
            v-if="!upToXLargeBreakpoint && !upToLargeBreakpoint"
            class="h-full pr-3"
          />
          <DarkModeToggle class="mr-2" />
          <AppNavActions />

          <!-- <AppNavToggle v-if="!upToLargeBreakpoint" />
          <AppNavOtherItems
            v-if=" !upToLargeBreakpoint"
            class="h-full pr-3"
          />
          <DarkModeToggle class="mr-2" />
          <AppNavActions /> -->
        </div>
      </div>
    </nav>
    <AppNavBelow />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import useBreakpoints from '@/composables/useBreakpoints';
import AppLogo from '@/components/images/AppLogo.vue';
// import AppIcon from '@/components/images/AppIcon.vue';
import AppNavAlert from './AppNavAlert.vue';
import AppNavOtherItems from './AppNavOtherItems.vue';

import AppNavToggle from './AppNavToggle.vue';
import AppNavActions from './AppNavActions.vue';
// import AppNavNetworkSelect from './AppNavNetworkSelect.vue';
// import useFathom from '@/composables/useFathom';
import useWeb3 from '@/services/web3/useWeb3';
import DarkModeToggle from '@/components/btns/DarkModeToggle.vue';
import useAlerts from '@/composables/useAlerts';
import AppNavBelow from './AppNavBelow';
import AppNavDummyAlert from './AppNavDummyAlert';
export default defineComponent({
  components: {
    AppNavBelow,
    AppLogo,
    // AppIcon,
    AppNavAlert,
    AppNavToggle,
    AppNavActions,
    // AppNavNetworkSelect,
    AppNavDummyAlert,
    AppNavOtherItems,
    DarkModeToggle
  },

  setup() {
    // COMPOSABLES
    const { bp, upToLargeBreakpoint } = useBreakpoints();
    // const { trackGoal, Goals } = useFathom();
    const { connector } = useWeb3();
    const { currentAlert } = useAlerts();

    // DATA
    const appNav = ref(null);

    // COMPUTED
    const hideNetworkSelect = computed(() => connector.value?.id === 'gnosis');

    // METHODS
    function handleScroll() {
      if (window.scrollY === 0) {
        appNav.value.classList.remove('shadow-lg');
      } else {
        appNav.value.classList.add('shadow-lg');
      }
    }

    // CALLBACKS
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      // data
      appNav,
      // computed
      bp,
      currentAlert,
      upToLargeBreakpoint,
      hideNetworkSelect
      // methods
      // trackGoal,
      // Goals
    };
  }
});
</script>

<style scoped>
/* #app-nav {
  @apply w-full z-20;
  @apply bg-white dark:bg-gray-900;
  @apply border-b border-transparent;
  transition: all 0.2s ease-in-out;
} */
#app-nav {
  @apply w-full z-50;
  @apply bg-white dark:bg-gray-800;
  /* @apply bg-white dark:bg-gray-900; */
  /* @apply bg-white dark:bg-black; */

  @apply border-b border-transparent;
  transition: all 0.2s ease-in-out;
}

/* .nav-container { */
/* position: absolute; */
/* width: 100%; */
/* padding: 11px 15px 0px 15px; */
/* } */
</style>
